import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layouts/MdxPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I've spent a lot of time over the years writing on code. Sometimes for money, other times for fun. Some projects get formal releases and versions, many more don't.`}</p>
    <h2 {...{
      "id": "greatest-hits",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#greatest-hits",
        "aria-label": "greatest hits permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Greatest Hits`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/reddit-user-to-sqlite"
        }}>{`reddit-user-to-sqlite`}</a>{`, a CLI tool for archiving data about a Reddit user. Made as part of the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/2023_Reddit_API_controversy"
        }}>{`2023 Reddit API Controversy`}</a>{`. I wrote `}<a parentName="li" {...{
          "href": "/blog/post/archive-your-reddit-data/"
        }}>{`a blog post about it`}</a>{`, and it was `}<a parentName="li" {...{
          "href": "https://www.wired.com/story/how-to-download-your-reddit-data/"
        }}>{`covered by Wired`}</a>{`.`}</li>
      <li parentName="ul">{`My `}<a parentName="li" {...{
          "href": "https://github.com/xavdid/advent-of-code"
        }}>{`Advent of Code solutions`}</a>{` (and accompanying `}<a parentName="li" {...{
          "href": "https://advent-of-code.xavd.id/"
        }}>{`solution writeups`}</a>{`) for the yearly `}<a parentName="li" {...{
          "href": "https://adventofcode.com/"
        }}>{`Advent of Code`}</a>{` puzzles (`}<a parentName="li" {...{
          "href": "/blog/post/building-aoc-showcase/"
        }}>{`more info`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/universal-test-runner"
        }}>{`Universal Test Runner`}</a>{`, for running the unit tests in any repo. Adapted into the `}<a parentName="li" {...{
          "href": "https://exercism.org"
        }}>{`Exercism`}</a>{` `}<a parentName="li" {...{
          "href": "https://exercism.org/docs/using/solving-exercises/working-locally"
        }}>{`CLI's`}</a>{` `}<inlineCode parentName="li">{`test`}</inlineCode>{` command (`}<a parentName="li" {...{
          "href": "https://github.com/exercism/cli/pull/1092"
        }}>{`PR`}</a>{`). This is my current favorite project; I `}<a parentName="li" {...{
          "href": "/blog/post/run-every-test/"
        }}>{`blogged about it`}</a>{`!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/typed-install"
        }}>{`Typed Install`}</a>{`, a CLI utility to smartly install type definitions when installing JS packages.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/heroku-config"
        }}>{`Heroku Config`}</a>{`, a Heroku plugin for managing project for your environment.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/epub-wordcount"
        }}>{`epub-wordcount`}</a>{`, a CLI tool for easily estimating the words in an `}<inlineCode parentName="li">{`epub`}</inlineCode>{` file.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/seymour"
        }}>{`Seymour`}</a>{`, a custom feed reader for Slack.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://davidbrownman.com/drafts"
        }}>{`Drafts JS Editor`}</a>{`, a web editor for writing `}<a parentName="li" {...{
          "href": "https://getdrafts.com/"
        }}>{`Drafts`}</a>{` workflows while taking advantage of Typescript auto-complete. This project, and subsequent collaboration with Drafts' creator `}<a parentName="li" {...{
          "href": "https://twitter.com/agiletortoise"
        }}>{`Greg Pierce`}</a>{`, led to a new `}<a parentName="li" {...{
          "href": "https://scripting.getdrafts.com/"
        }}>{`scripting documentation site`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/xavdid.github.io/"
        }}>{`This Website`}</a>{`! It's the third major version of my personal site, this time using React.js and Gatsby. `}{
          /* I wrote more about the latest version here */
        }</li>
    </ul>
    <h3 {...{
      "id": "via-a-job",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#via-a-job",
        "aria-label": "via a job permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Via a Job`}</h3>
    <ul>
      <li parentName="ul">{`While at `}<a parentName="li" {...{
          "href": "https://stripe.com"
        }}>{`Stripe`}</a>{`, all of my public open source contributions are available under the `}<a parentName="li" {...{
          "href": "https://github.com/xavdid-stripe"
        }}>{`xavdid-stripe`}</a>{` GitHub user. So far, I've made contributions to the Stripe CLI and influenced the design of the Stripe SDKs (especially Node.js and Python).`}</li>
      <li parentName="ul">{`While at `}<a parentName="li" {...{
          "href": "https://zapier.com/"
        }}>{`Zapier`}</a>{`, I was a primary maintainer of the `}<a parentName="li" {...{
          "href": "https://github.com/zapier/zapier-platform"
        }}>{`Zapier Platform`}</a>{`, consisting of a CLI, SDK, and JSON Schema. I triaged issues, performed important refactors, and focused on performance optimizations.`}</li>
      <li parentName="ul">{`While at `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/SalesforceIQ"
        }}>{`RelateIQ`}</a>{`, I wrote the `}<a parentName="li" {...{
          "href": "https://github.com/xavdid/ruby-sdk"
        }}>{`RelateIQ Ruby SDK`}</a>{`. This was during the height of my ruby days while working at RelateIQ, this was a fully tested and documented UX-focused wrapper for reading and writing from their API.`}</li>
    </ul>
    <h3 {...{
      "id": "closed-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#closed-source",
        "aria-label": "closed source permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Closed Source`}</h3>
    <p>{`These projects aren't available publicly, so you'll just have to take my word for it that they were really cool.`}</p>
    <ul>
      <li parentName="ul">{`At Stripe, I made contributions to the Stripe `}<a parentName="li" {...{
          "href": "https://support.stripe.com/topics/dashboard"
        }}>{`Dashboard`}</a>{` and some of our backend tooling. You've likely seen my work if you've tried to change a setting while in `}<a parentName="li" {...{
          "href": "https://docs.stripe.com/test-mode#test-mode"
        }}>{`Test Mode`}</a>{` or created a sandbox.`}</li>
      <li parentName="ul">{`At Zapier, my first big project on the platform team was to re-write the Slack integration to take advantage of their new webhook features. If you used Slack with Zapier anytime after May 2017, you were using my code!`}</li>
      <li parentName="ul">{`I also implemented a version of the public Zapier Developer page. You can see a (mostly working) version of it at the `}<a parentName="li" {...{
          "href": "https://web.archive.org/web/20180326060057if_/https://zapier.com/developer/"
        }}>{`Wayback Machine`}</a></li>
      <li parentName="ul">{`At RelateIQ, my primary job was "Internal Tools". The best one was a internal admin site built with Sinatra that read from our production Mongo database.`}</li>
    </ul>
    <h2 {...{
      "id": "b-sides",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-sides",
        "aria-label": "b sides permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B-Sides`}</h2>
    {
      /* - [Kerfuffle](https://kerfuffle.herokuapp.com/), a custom web UI to help me randomly pick a show/book/movie. It's gone through a few major iterations and technology changes. Version 1 used Angular 1! */
    }
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/will-it-play"
        }}>{`Will it Play?`}</a>{`, a CLI tool for checking if a video file is likely to play on a PS4 (and PS5).`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/json-requests"
        }}>{`JSON Requests`}</a>{`, an extremely barebones HTTP request library for Typescript.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/generator-xavdid"
        }}>{`generator-xavdid`}</a>{`, a `}<a parentName="li" {...{
          "href": "https://yeoman.io/"
        }}>{`Yeoman`}</a>{` generator for creating Typescript projects. Works for CLIs, frontend, backend, or any combination thereof!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/airtable-grouped-chart"
        }}>{`Airtable Grouped Chart`}</a>{`, my award winning (I can't find a link anywhere, but I got a bunch of Airtable credit for this!) custom block for building charts based on grouped properties.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/refbook"
        }}>{`refbook`}</a>{`, a website that provided automated testing and user registration for the now-defunct International Referee Development Program of `}<del parentName="li">{`quidditch`}</del>{` quadball. This was my first large-scale solo project and provided an invaluable start to my career in web development. It was hosted at `}<inlineCode parentName="li">{`refdevelopment.com`}</inlineCode></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serenity-for-sale.netlify.app/"
        }}>{`serenity.forsale`}</a>{`, a Gatsby site to sell our converted campervan. The site's source is `}<a parentName="li" {...{
          "href": "https://github.com/xavdid/serenity-for-sale"
        }}>{`on GitHub`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/monkey-ts"
        }}>{`Monkey TS`}</a>{`, an implementation of the Monkey programming language written in Typescript. See also: `}<em parentName="li"><a parentName="em" {...{
            "href": "https://interpreterbook.com/"
          }}>{`Writing an Interpreter in Go`}</a></em>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/publists"
        }}>{`Publists`}</a>{`, a project I wrote during my 2016 funemployment to easily share `}<a parentName="li" {...{
          "href": "https://www.wunderlist.com/"
        }}>{`Wunderlists`}</a>{` (RIP) publicly.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/pitch_awesome"
        }}>{`Pitch Awesome`}</a>{` was my one and only iOS app based on an idea given to me by my (then) girlfriend. It was a simple pitch pipe that could store a set of opening notes for songs. It's still on my phone to this day!`}
        {
          /* - [Stack Pro](https://stackpro.herokuapp.com/), a little webapp I wrote as part of a job app for a now-defunct contracting outfit. Notable because as of time of writing, I believe this holds the record for longest-running code without modification. It's not complex, but it's been operating flawlessly since 2015. */
        }
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://davidbrownman.com/monopoly/"
        }}>{`Monopoly Tracker`}</a>{`, a static site that lets users track which Safeway Monopoly tickets they've picked up. Spoiler: you never win.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/bgroups"
        }}>{`Band Groups`}</a>{`, a simple static page I wrote for a friend to easily sort a large list of students into groups.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/dead_simple"
        }}>{`Dead Simple`}</a>{`, a proof of concept for a totally ephemeral, anonymous chat with web sockets.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/countdown"
        }}>{`Countdown`}</a>{`, a site that shows factoids to help you visualize how far away a date is. Mostly defunct now (since the event we were counting down to has passed), but some pretty cute code.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/xavdid/lolapi"
        }}>{`LoL API`}</a>{`, the first major project I worked on. I was hoping to develop a data API for League of Legends data (before `}<a parentName="li" {...{
          "href": "https://www.riotgames.com/en"
        }}>{`Riot Games`}</a>{` released `}<a parentName="li" {...{
          "href": "https://developer.riotgames.com/docs/lol#data-dragon"
        }}>{`theirs`}</a>{`. I had a mini CLI that could simulate two characters fighting (on a super simple level).`}</li>
      <li parentName="ul">{`I wrote a `}<a parentName="li" {...{
          "href": "https://gist.github.com/xavdid/d07ef908fe023f2e1225"
        }}>{`simple python script`}</a>{` on a plane to let a pair of people play `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Ultimate_tic-tac-toe"
        }}>{`Ultimate Tic-Tac-Toe`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "appearances",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#appearances",
        "aria-label": "appearances permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Appearances`}</h2>
    <p>{`I've been interviewed for various external projects. Here's a list, most recent to least:`}</p>
    <ul>
      <li parentName="ul">{`My work on archiving one's Reddit data was featured in `}<a parentName="li" {...{
          "href": "https://www.wired.com/story/how-to-download-your-reddit-data/"
        }}>{`a post on Wired`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=yDL98sd4KVE"
        }}>{`JSON Schema in Production, ep 4`}</a>{`, interviewed by Ben Hutton.`}</li>
      <li parentName="ul">{`For current Zapiens, I was on an episode of the `}<em parentName="li">{`Zapier Internal Podcast`}</em>{`, released `}<inlineCode parentName="li">{`2022-05-30`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "notable-open-source-contributions-prs-and-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#notable-open-source-contributions-prs-and-issues",
        "aria-label": "notable open source contributions prs and issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Notable Open-Source Contributions, PRs and Issues`}</h2>
    <p>{`There's an exhaustive list of my contributions using `}<a parentName="p" {...{
        "href": "https://github.com/issues?q=author%3Axavdid+archived%3Afalse+-user%3Axavdid+-user%3Aquidtech+"
      }}>{`this GitHub search`}</a>{` (must be logged in), but here are the coolest ones:`}</p>
    <h3 {...{
      "id": "prs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#prs",
        "aria-label": "prs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PRs`}</h3>
    <ul>
      <li parentName="ul">{`Improved performance of the Mastodon iOS app's Go-based push notification service (`}<a parentName="li" {...{
          "href": "https://github.com/mastodon/webpush-apn-relay/pull/1"
        }}>{`mastodon/webpush-apn-relay#1`}</a>{` and `}<a parentName="li" {...{
          "href": "https://github.com/mastodon/webpush-apn-relay/pull/2"
        }}>{`#2`}</a>{`)`}</li>
      <li parentName="ul">{`Fixed `}<a parentName="li" {...{
          "href": "https://github.com/LearningTypeScript/projects/pulls?q=author%3Axavdid"
        }}>{`multiple typos and consistency issues`}</a>{` to the projects for the Learning Typescript book`}</li>
      <li parentName="ul">{`Added a check for DRM in epub files to the `}<inlineCode parentName="li">{`epub`}</inlineCode>{` package on `}<inlineCode parentName="li">{`npm`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/julien-c/epub/pull/44"
        }}>{`julien-c/epub#44`}</a>{`)`}</li>
      <li parentName="ul">{`Wrote Typescript types for the `}<inlineCode parentName="li">{`striptags`}</inlineCode>{` package (`}<a parentName="li" {...{
          "href": "https://github.com/ericnorris/striptags/pull/44"
        }}>{`ericnorris/striptags#44`}</a>{`) and removed them from `}<inlineCode parentName="li">{`DefinitelyTyped`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/pull/22040"
        }}>{`DefinitelyTyped#22040`}</a>{`)`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`isSpinning`}</inlineCode>{` property to `}<inlineCode parentName="li">{`ora`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/sindresorhus/ora/pull/73"
        }}>{`sindresorhus/ora#73`}</a>{`)`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`files`}</inlineCode>{` property to the `}<inlineCode parentName="li">{`mercury-parser`}</inlineCode>{` package, to reduce the size of the shipped code (`}<a parentName="li" {...{
          "href": "https://github.com/postlight/mercury-parser/pull/269"
        }}>{`postlight/mercury-parser#269`}</a>{`)`}</li>
      <li parentName="ul">{`Swapped some bash install links in docs to `}<inlineCode parentName="li">{`https`}</inlineCode>{` in `}<inlineCode parentName="li">{`react-native`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/facebook/react-native/pull/12090"
        }}>{`facebook/react-native#12090`}</a>{`)`}</li>
      <li parentName="ul">{`Tried to add support for creating new Middleman posts with content pre-filled (`}<a parentName="li" {...{
          "href": "https://github.com/middleman/middleman-blog/pull/337"
        }}>{`middleman/middleman-blog#337`}</a>{`)`}</li>
      <li parentName="ul">{`In addition to maintaining the `}<inlineCode parentName="li">{`parse-ruby-client`}</inlineCode>{` for a time, I added the `}<inlineCode parentName="li">{`keys`}</inlineCode>{` operator to ruby Parse queries (`}<a parentName="li" {...{
          "href": "https://github.com/adelevie/parse-ruby-client/pull/148"
        }}>{`adelevie/parse-ruby-client#148`}</a>{`)`}</li>
      <li parentName="ul">{`Fixed some typos in `}<inlineCode parentName="li">{`botkit`}</inlineCode>{`. I honestly love this one, because in hindsight I absolutely misinterpreted their instructions to "Include screenshots and animated GIFs in your pull request whenever possible" (`}<a parentName="li" {...{
          "href": "https://github.com/howdyai/botkit/pull/128"
        }}>{`howdyai/botkit#128`}</a>{`)`}</li>
      <li parentName="ul">{`Fixed a typo in the Standard Ebooks version of Siddhartha (`}<a parentName="li" {...{
          "href": "https://github.com/standardebooks/hermann-hesse_siddhartha_gunther-olesch_anke-dreher_amy-coulter_stefan-langer_semyon-chaichenets/pull/3"
        }}>{`standardebooks/siddhartha#3`}</a>{`)`}</li>
      <li parentName="ul">{`Fixed a typo in the Yarn website (`}<a parentName="li" {...{
          "href": "https://github.com/yarnpkg/website/pull/909"
        }}>{`yarnpkg/website#909`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#issues",
        "aria-label": "issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Issues`}</h3>
    <ul>
      <li parentName="ul">{`VSCode issue about not updating disabled extensions (`}<a parentName="li" {...{
          "href": "https://github.com/microsoft/vscode/issues/22461"
        }}>{`microsoft/vscode#22461`}</a>{`)`}</li>
      <li parentName="ul">{`VSCode issue encouraging smarter default save paths for new files (`}<a parentName="li" {...{
          "href": "https://github.com/microsoft/vscode/issues/22697"
        }}>{`microsoft/vscode#22697`}</a>{`)`}</li>
      <li parentName="ul">{`VSCode issue that caused big slowdowns in the integrated terminal when there are a lot of escape characters in output (`}<a parentName="li" {...{
          "href": "https://github.com/microsoft/vscode/issues/24795"
        }}>{`microsoft/vscode#24795`}</a>{`)`}</li>
      <li parentName="ul">{`Filed an issue about `}<inlineCode parentName="li">{`require`}</inlineCode>{`-ing real files within a mocked filesystem, which was fixed, broken, and then finally fixed again (`}<a parentName="li" {...{
          "href": "https://github.com/tschaub/mock-fs/issues/130"
        }}>{`tschaub/mock-fs#130`}</a>{`)`}</li>
    </ul>
    {
      /* ## Non Technical */
    }
    {
      /* If I want to bother with either a json resume or those cool components I liked, it could go here. */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      